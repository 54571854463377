  // import Vue from 'vue'
  // import VueCompositionAPI, { createApp, h } from '@vue/composition-api'

  // import store from './store'
  // import "./axios";
  // import router from './router'
  // import App from './App.vue'

  // import PrimeVue from 'primevue/config';
  // import 'primevue/resources/themes/saga-blue/theme.css'; // Choose a theme
  // import 'primevue/resources/primevue.min.css'; // Core CSS
  // import 'primeicons/primeicons.css';

  // // Bootstrap
  // import { BootstrapVueIcons, PopoverPlugin, ToastPlugin, ModalPlugin, SidebarPlugin, TooltipPlugin, TabsPlugin,IconsPlugin  } from 'bootstrap-vue'
  // import 'bootstrap-vue/dist/bootstrap-vue.css'
  // import 'bootstrap/dist/css/bootstrap.css';

  // // Highlightjs
  // import VueHighlightJS from 'vue-highlightjs';
  // import 'highlight.js/styles/mono-blue.css';

  // // Apexcharts
  // import VueApexCharts from 'vue-apexcharts'


  // // Bootstrap Plugin
  // Vue.use(TabsPlugin)
  // Vue.use(TooltipPlugin)
  // Vue.use(SidebarPlugin)
  // Vue.use(PopoverPlugin)
  // Vue.use(ToastPlugin)
  // Vue.use(ModalPlugin)
  // Vue.use(BootstrapVueIcons)
  // Vue.use(IconsPlugin);

  // // Highlight
  // Vue.use(VueHighlightJS)

  // // Apexchart
  // Vue.use(VueApexCharts)
  // Vue.component('apexchart', VueApexCharts)

  // // Style
  // require('@/assets/icons/iconly/index.min.css')
  // require('@/assets/icons/remix-icon/index.min.css')
  // require('@/assets/scss/app.scss')

  // // Composition Api
  // Vue.use(VueCompositionAPI)

  // Vue.config.productionTip = false

  // new Vue({
  //   router,
  //   store,
  //   render: h => h(App),
  // }).$mount('#app');

  // const app = createApp(App);
  // app.use(PrimeVue);
  // app.mount('#app');

import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import store from './store';
import './axios';
import router from './router';
import App from './App.vue';

// PrimeVue
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/saga-blue/theme.css'; // Choose a theme
import 'primevue/resources/primevue.min.css'; // Core CSS
import 'primeicons/primeicons.css';

// BootstrapVue
import { BootstrapVueIcons, PopoverPlugin, ToastPlugin, ModalPlugin, SidebarPlugin, TooltipPlugin, TabsPlugin, IconsPlugin } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';

// Highlight.js
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/mono-blue.css';

// ApexCharts
import VueApexCharts from 'vue-apexcharts';

// BootstrapVue Plugin
Vue.use(TabsPlugin);
Vue.use(TooltipPlugin);
Vue.use(SidebarPlugin);
Vue.use(PopoverPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVueIcons);
Vue.use(IconsPlugin);

// Highlight.js
Vue.use(VueHighlightJS);

// ApexCharts
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// PrimeVue
Vue.use(PrimeVue);

// Composition API
Vue.use(VueCompositionAPI);

// Style
require('@/assets/icons/iconly/index.min.css');
require('@/assets/icons/remix-icon/index.min.css');
require('@/assets/scss/app.scss');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
